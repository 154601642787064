<template>
    <!-- 绑定社交账号 -->
    <div class="social_accounts_box">
        <el-form
            label-width="90px"
            label-position="left">
            <!-- 微信 -->
            <el-form-item>
                <!-- 标题 -->
                <template slot="label">
                    <div class="social_title">
                        <img :src="require('@/assets/images/center/@1x/icon_circle_wechat.png')" alt="">
                        <!-- <img :src="require('@/assets/images/center/@1x/icon_gray_circle_wechat.png')" alt=""> -->
                        <span>微信：</span>
                    </div>
                </template>
                <!-- 内容 -->
                <div class="social_content is_bind">
                   <span>Andychen</span>
                    <el-button 
                        type="text"
                        class="binding_btn">解除绑定</el-button> 
                </div>
            </el-form-item>
            <!-- 微博 -->
            <el-form-item>
                <!-- 标题 -->
                <template slot="label">
                    <div class="social_title">
                        <!-- <img :src="require('@/assets/images/center/@1x/icon_circle_sina.png')" alt=""> -->
                        <img :src="require('@/assets/images/center/@1x/icon_gray_circle_sina.png')" alt="">
                        <span>微博：</span>
                    </div>
                </template>
                <!-- 内容 -->
                <div class="social_content">
                    <span>未绑定</span>
                    <el-button 
                        type="text"
                        class="binding_btn">绑定</el-button>
                </div>
            </el-form-item>
            <!-- QQ -->
            <el-form-item>
                <!-- 标题 -->
                <template slot="label">
                    <div class="social_title">
                        <!-- <img :src="require('@/assets/images/center/@1x/icon_circle_qq.png')" alt=""> -->
                        <img :src="require('@/assets/images/center/@1x/icon_gray_circle_qq.png')" alt="">
                        <span>QQ：</span>
                    </div>
                </template>
                <!-- 内容 -->
                <div class="social_content">
                    <span>未绑定</span>
                    <el-button 
                        type="text"
                        class="binding_btn">绑定</el-button>
                </div>
            </el-form-item>
            <!-- 豆瓣 -->
            <el-form-item>
                <!-- 标题 -->
                <template slot="label">
                    <div class="social_title">
                        <!-- <img :src="require('@/assets/images/center/@1x/icon_circle_douban.png')" alt=""> -->
                        <img :src="require('@/assets/images/center/@1x/icon_gray_circle_douban.png')" alt="">
                        <span>豆瓣：</span>
                    </div>
                </template>
                <!-- 内容 -->
                <div class="social_content">
                    <span>未绑定</span>
                    <el-button 
                        type="text"
                        class="binding_btn">绑定</el-button>
                </div>
            </el-form-item>
        </el-form>
        <!-- 提交区域 -->
        <!-- <div style="margin-top:70px">
            <el-button 
                type="warning" 
                class="give_up_btn">保存</el-button>
            <el-button 
                type="warning" 
                plain
                class="give_up_btn">取消</el-button>
        </div> -->
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
/* element-ui */
.el-form-item{
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

/* 绑定社交账号盒子 */
.social_accounts_box{
    padding-bottom: 30px;
}
/* 表单标题区域 */
.social_title>img{
    margin-right: 5px;
}
.social_title>span{
    color: #909399;
}
/* 绑定按钮 */
.binding_btn{
    float: right;
    color: #E6A23C;
}
/* 绑定时字体样式 */
.is_bind>span{
    color:#409EFF;
}
.is_bind>.binding_btn{
    color: #F56C6C;
}

/* 上传按钮 */
.give_up_btn{
    width: 100px;
}
</style>
